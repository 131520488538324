@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #9e9d9e;
  border-radius: 6px;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/Beautiella.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'GreatVibes';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cost-circle {
  box-shadow: 4px -5px 12px -3px #000;
}

.image-circle {
  box-shadow: 0 0 21px -1px #000;
}


.btn {
  min-width: 160px;
  width: fit-content;
  width: -moz-fit-content;
  background-color: white;
  padding: 0 30px;
  height: 50px;
  font-family: "Open Sans", sans-serif;
  color: #b26b53;
  letter-spacing: 2.8px;
  font-weight: 700;
  line-height: 1.6;
  box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.3);
  position: relative;
  transition: all 0.4s ease;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* btn text */
  span {
     z-index: 1;
     text-align: center;
  }

  &:before,
  &:after {
     content: "";
     position: absolute;
     width: 4px;
     height: 100%;
     top: 0;
     transition: all 0.4s ease;
     background-color: #b26b53;
  }

  &:before {
     left: 0;
  }

  &:after {
     right: 0;
  }

  &:hover {
     transition: all 0.4s ease;
     box-shadow: 0 8px 20px -12px rgba(0, 0, 0, 0.2);
     letter-spacing: 2px;
     color: white;

     &:before,
     &:after {
        width: 51%;
     }
  }
}

/* Define the keyframes for the color change */
@keyframes infiniteColorChange {
   0% {
     background-color: rgb(187, 62, 62);
   }
   40% {
     background-color: gold;
     box-shadow: 0px 0px 10px 5px gold;
   }
   100% {
      background-color: red;
   }
 }
 
 /* Apply the animation to an element */
 .animated {
   padding: 5px 20px;
   animation: infiniteColorChange 2s infinite; /* Change '5s' to adjust the speed */
 }
